<!-- 新闻详情页面 -->
<template>
  <div class="newsDetail">
    <my-banner :banner="banner" />
    <div class="main">
      <div class="title">{{ data.noticeTitle }}</div>
      <div class="time">发布时间：{{ data.createTime }}</div>
      <div class="img">
        <img :src="data.noticePicture" />
      </div>
      <div class="content text" v-html="data.noticeContent"></div>
      <div class="pageTurning">
        <div class="prev">
          <div v-show="index > 0">
            上一篇：
            <span @click="goToDetail(index - 1)">{{ this.prev }}</span>
          </div>
        </div>
        <div class="next">
          <div v-show="index + 1 < total">
            下一篇：
            <span @click="goToDetail(index + 1)">{{ this.next }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetails, getData, queryPhotoManageList } from "@/api/api.js";
import {} from "@/api/api.js";
export default {
  name: "newsDetail",
  data() {
    return {
      banner: "",
      data: {}, //详情
      index: 0, //当前详情页下标
      total: 0, //查询到的数据总数
      prev: "",
      next: "",
      result: [],
      id: 0,
    };
  },
  created() {
    queryPhotoManageList({
      photoPage: "新闻动态",
    }).then((res) => {
      this.banner = res.data.rows[0].photoPicture;
    });
  },
  methods: {
    //上一篇下一篇
    goToDetail(index) {
      console.log(index);
      console.log(this.result[index]);
      this.$router.push({
        path: "/newsDetail",
        query: {
          noticeId: this.result[index].noticeId,
          index,
          id: this.id,
          total: this.total,
        },
      });
    },
  },
  watch: {
    $route: {
      handler() {
        this.index = parseInt(this.$route.query.index);
        this.total = parseInt(this.$route.query.total);
        this.id = parseInt(this.$route.query.id);
        getDetails({
          id: this.$route.query.noticeId,
        }).then((res) => {
          this.data = res.data.data;
        });

        getData({
          noticeType: this.$route.query.id,
          pageNum: 1,
          pageSize: this.$route.query.total,
        }).then((res) => {
          const result = res.data.rows;
          this.result = result;
          if (this.index > 0) {
            this.prev = result[this.index - 1].noticeTitle;
          }
          if (this.index + 1 < this.total) {
            this.next = result[this.index + 1].noticeTitle;
          }
        });
      },
      immediate: true,
    },
  },
};
</script>
<style lang='less' scoped>
@media screen and(max-width: 1200px) {
  .newsDetail {
    .main {
      width: 70% !important;
    }
  }
}
@media screen and(max-width: 788px) {
  .newsDetail {
    .main {
      width: 90% !important;
    }
  }
}
.newsDetail {
  .main {
    box-shadow: 0px 0px 10px 0px rgba(210, 5, 5, 0.32);
    padding: 20px;
    max-width: 1100px;
    width: 55%;
    margin: 50px auto;
    .title {
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      color: #333333;
    }
    .time {
      text-align: center;
      margin-top: 10px;
      color: #454545;
      font-size: 12px;
    }
    .img {
      margin-top: 20px;
      text-align: center;
      > img {
        width: 80%;
      }
    }
    .content {
      margin-top: 30px;
      /deep/ * {
        img {
          max-width: 100% !important;
        }
      }
    }
    .pageTurning {
      display: flex;
      justify-content: space-between;
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: 200px;
        span {
          color: #d20505;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}
</style>